import React, { useEffect, useState } from "react";
import { Column, Label, Panel, Row } from "@kortvaluta/admin-twooca-react";
import { toDate } from "../../../utils/dateUtils";
import {
    ISearchAction,
    SearchUserOption,
} from "../../../store/healthcareSearch/models";
import ActionBtn from "../actionButton/ActionBtn";
import SearchBox from "./searchBox/SearchBox";
import SearchDateInput from "./searchDateInput/SearchDateInput";
import SearchSelect from "./searchSelect";
import "./styles.scss";
import Radio from "../radio/item/RadioItem";
import RadioGroup from "../radio/group/RadioGroup";

// 初期値を別の変数として定義
const initialHeartRange = {
    heartRateRange: { start: "", end: "" },
    averageHeartRateRange: { start: "", end: "" },
};
const initialStepRange = {
    stepCountRange: { start: "", end: "" },
    averageStepCountRange: { start: "", end: "" },
};
const initialTemperatureRange = {
    temperatureRange: { start: "", end: "" },
    averageTemperatureRange: { start: "", end: "" },
};
const initialSleepRange = {
    sleepTimeRange: { start: "", end: "" },
    averageSleepTimeRange: { start: "", end: "" },
};
const initialDateRangeValue = { start: "", end: "" };
const initialSearchInput = {
    startHeartInput: true,
    endHeartInput: true,
    startAverageHeartInput: true,
    endAverageHeartInput: true,
    startStepsInput: true,
    endStepsInput: true,
    startAverageStepsInput: true,
    endAverageStepsInput: true,
    startTemperatureInput: true,
    endTemperatureInput: true,
    startAverageTemperatureInput: true,
    endAverageTemperatureInput: true,
    startSleepInput: true,
    endSleepInput: true,
    startAverageSleepInput: true,
    endAverageSleepInput: true,
};
const initialSearchUser = {
    label: "",
    value: "",
    name: "",
};

const SearchContainer: React.FC<ISearchAction> = ({
    target,
    userOptions,
    selectMode,
    setSearchParams,
    handleSearch,
}) => {
    // useStateの初期値を変数で設定
    const [heartRange, setHeartRange] = useState(initialHeartRange);
    const [stepRange, setStepRange] = useState(initialStepRange);
    const [temperatureRange, setTemperatureRange] = useState(
        initialTemperatureRange
    );
    const [sleepRange, setSleepRange] = useState(initialSleepRange);
    const [dateRangeValue, setDateRangeValue] = useState(initialDateRangeValue);
    const [searchInput, setSearchInput] = useState(initialSearchInput);
    const [user, setUser] = useState<SearchUserOption>(initialSearchUser);
    const [isDateValue, setIsDateValue] = useState(true);
    const [selectedMode, setSelectedMode] = useState<"record" | "summary">(
        "record"
    );

    const handleUserNameChange = (user?: SearchUserOption) => {
        if (user) {
            setUser(user);
        } else {
            setUser(initialSearchUser);
        }
    };

    // validateInputs関数を抽出
    const validateInputs = () => {
        const isDateRangeEmpty =
            dateRangeValue.start === "" && dateRangeValue.end === "";
        const checkRange = (range: any, key: string) => {
            if (key in range) {
                const { start, end } = range[key];
                const { start: avgStart, end: avgEnd } =
                    range[
                        `average${key.charAt(0).toUpperCase() + key.slice(1)}`
                    ];
                return (
                    start !== "" ||
                    end !== "" ||
                    avgStart !== "" ||
                    avgEnd !== ""
                );
            }
            return false;
        };

        const isRangeEmpty = [
            heartRange,
            stepRange,
            temperatureRange,
            sleepRange,
        ].every((range) =>
            [
                "heartRateRange",
                "stepCountRange",
                "temperatureRange",
                "sleepTimeRange",
            ].every((key) => !checkRange(range, key))
        );

        return isDateRangeEmpty && isRangeEmpty;
    };

    // setSearchInputs関数を抽出
    const setSearchInputs = () => {
        setSearchInput({
            startHeartInput: true,
            endHeartInput: true,
            startAverageHeartInput: true,
            endAverageHeartInput: true,
            startStepsInput: true,
            endStepsInput: true,
            startAverageStepsInput: true,
            endAverageStepsInput: true,
            startTemperatureInput: true,
            endTemperatureInput: true,
            startAverageTemperatureInput: true,
            endAverageTemperatureInput: true,
            startSleepInput: true,
            endSleepInput: true,
            startAverageSleepInput: true,
            endAverageSleepInput: true,
        });
    };

    // onSearchClick関数をリファクタリング
    const onSearchClick = () => {
        const isInputEmpty = validateInputs();
        setSearchInputs();

        if (isInputEmpty) {
            const params = {
                user,
                dateRangeValue: { start: null, end: null },
                heartRange: heartRange.heartRateRange,
                averageHeartRange: heartRange.averageHeartRateRange,
                stepCountRange: stepRange.stepCountRange,
                averageStepCountRange: stepRange.averageStepCountRange,
                temperatureRange: temperatureRange.temperatureRange,
                averageTemperatureRange:
                    temperatureRange.averageTemperatureRange,
                sleepTimeRange: sleepRange.sleepTimeRange,
                averageSleepTimeRange: sleepRange.averageSleepTimeRange,
            };
            setSearchParams(params);
            handleSearch(params);
        } else {
            const params = {
                user,
                dateRangeValue,
                heartRange: heartRange.heartRateRange,
                averageHeartRange: heartRange.averageHeartRateRange,
                stepCountRange: stepRange.stepCountRange,
                averageStepCountRange: stepRange.averageStepCountRange,
                temperatureRange: temperatureRange.temperatureRange,
                averageTemperatureRange:
                    temperatureRange.averageTemperatureRange,
                sleepTimeRange: sleepRange.sleepTimeRange,
                averageSleepTimeRange: sleepRange.averageSleepTimeRange,
            };
            setSearchParams(params);
            handleSearch(params);
        }
    };

    // handleDateData関数でdate-fnsを使用
    const handleDateData = (value: [Date | null, Date | null]) => {
        if (value[0] === null || value[1] === null) {
            setDateRangeValue({ start: "", end: "" });
        } else {
            const startDate = toDate(value[0]);
            const endDate = toDate(value[1]);
            setDateRangeValue({
                start: startDate,
                end: endDate,
            });
        }
    };

    // setRange関数を抽出
    const setRange = (
        setter: React.Dispatch<React.SetStateAction<any>>,
        key: string,
        value: { start: string; end: string }
    ) => {
        setter((prevState: any) => ({
            ...prevState,
            [key]: {
                start: value.start,
                end: value.end,
            },
        }));
    };

    const onSelectMode = (e: any) => {
        const value = e.target.value as "record" | "summary";
        setSelectedMode(value);
        selectMode(value);
        resetSearchOptionValue();
    };

    const resetSearchOptionValue = () => {
        const resetValue = { start: "", end: "" };
        const resetOption = [
            {
                setter: setHeartRange,
                keys: ["heartRateRange", "averageHeartRateRange"],
            },
            {
                setter: setStepRange,
                keys: ["stepCountRange", "averageStepCountRange"],
            },
            {
                setter: setTemperatureRange,
                keys: ["temperatureRange", "averageTemperatureRange"],
            },
            {
                setter: setSleepRange,
                keys: ["sleepTimeRange", "averageSleepTimeRange"],
            },
        ];
        resetOption.map((r) => {
            r.keys.map((key) => {
                setRange(r.setter, key, resetValue);
            });
        });
    };

    useEffect(() => {
        resetSearchOptionValue();
    }, [target]);

    return (
        <Panel>
            <div className="search-container__text">
                ✓必要な項目にご入力ください（ブランクの場合は全件が対象となります）
            </div>
            <div className="search-date">
                <Row>
                    <Column xs={12} md={4}>
                        <SearchDateInput
                            isDateRange={isDateValue}
                            setDateRange={handleDateData}
                        />
                        {isDateValue}
                    </Column>
                    <Column xs={12} md={6}>
                        <SearchSelect
                            options={userOptions}
                            label="氏名"
                            placeholder="氏名を選択してください"
                            onChange={handleUserNameChange}
                        />
                    </Column>
                    <div style={{ marginTop: "20px" }}>
                        <Column xs={12} md={12}>
                            <Label text={"表示データ形式"} />
                            <RadioGroup>
                                <Radio
                                    value="record"
                                    onChange={onSelectMode}
                                    label="集計前"
                                    name="mode"
                                    selected={selectedMode}
                                />
                                <Radio
                                    value="summary"
                                    onChange={onSelectMode}
                                    name="mode"
                                    label="集計後"
                                    selected={selectedMode}
                                />
                            </RadioGroup>
                        </Column>
                    </div>
                </Row>
            </div>
            <Row>
                {target === "心拍" && (
                    <Column xs={12} sm={6} md={6}>
                        <SearchBox
                            title="心拍"
                            label="心拍数 (回/分)"
                            rangeValue={heartRange.heartRateRange}
                            setRangeValue={(value) =>
                                setRange(setHeartRange, "heartRateRange", value)
                            }
                            labelAverage="平均 (回/期間)"
                            rangeAverageValue={heartRange.averageHeartRateRange}
                            setRangeAverageValue={(value) =>
                                setRange(
                                    setHeartRange,
                                    "averageHeartRateRange",
                                    value
                                )
                            }
                            styleStart={searchInput.startHeartInput}
                            styleEnd={searchInput.endHeartInput}
                            styleStartAverage={
                                searchInput.startAverageHeartInput
                            }
                            styleEndAverage={searchInput.endAverageHeartInput}
                            maxLength={3}
                            selectedMode={selectedMode}
                        />
                    </Column>
                )}
                {target === "歩数" && (
                    <Column xs={12} sm={6} md={6}>
                        <SearchBox
                            title={"歩数"}
                            label={"歩数 (歩/日)"}
                            rangeValue={{
                                start: stepRange.stepCountRange.start,
                                end: stepRange.stepCountRange.end,
                            }}
                            setRangeValue={(value) =>
                                setRange(setStepRange, "stepCountRange", value)
                            }
                            labelAverage={"累積歩数 (歩)"}
                            rangeAverageValue={{
                                start: stepRange.averageStepCountRange.start,
                                end: stepRange.averageStepCountRange.end,
                            }}
                            setRangeAverageValue={(value) =>
                                setRange(
                                    setStepRange,
                                    "averageStepCountRange",
                                    value
                                )
                            }
                            styleStart={searchInput.startStepsInput}
                            styleEnd={searchInput.endStepsInput}
                            styleStartAverage={
                                searchInput.startAverageStepsInput
                            }
                            styleEndAverage={searchInput.endAverageStepsInput}
                            maxLength={5}
                            selectedMode={selectedMode}
                        />
                    </Column>
                )}
                {target === "体温" && (
                    <Column xs={12} sm={6} md={6}>
                        <SearchBox
                            title={"体温"}
                            label={"体温 (度)"}
                            rangeValue={{
                                start: temperatureRange.temperatureRange.start,
                                end: temperatureRange.temperatureRange.end,
                            }}
                            setRangeValue={(value) =>
                                setRange(
                                    setTemperatureRange,
                                    "temperatureRange",
                                    value
                                )
                            }
                            labelAverage={"平均 (度/期間)"}
                            rangeAverageValue={{
                                start: temperatureRange.averageTemperatureRange
                                    .start,
                                end: temperatureRange.averageTemperatureRange
                                    .end,
                            }}
                            setRangeAverageValue={(value) =>
                                setRange(
                                    setTemperatureRange,
                                    "averageTemperatureRange",
                                    value
                                )
                            }
                            styleStart={searchInput.startTemperatureInput}
                            styleEnd={searchInput.endTemperatureInput}
                            styleStartAverage={
                                searchInput.startAverageTemperatureInput
                            }
                            styleEndAverage={
                                searchInput.endAverageTemperatureInput
                            }
                            maxLength={4}
                            selectedMode={selectedMode}
                        />
                    </Column>
                )}
                {target === "睡眠" && (
                    <Column xs={12} sm={6} md={6}>
                        <SearchBox
                            title={"睡眠"}
                            label={"睡眠時間(時間)/日"}
                            rangeValue={{
                                start: sleepRange.sleepTimeRange.start,
                                end: sleepRange.sleepTimeRange.end,
                            }}
                            setRangeValue={(value) =>
                                setRange(setSleepRange, "sleepTimeRange", value)
                            }
                            labelAverage={"平均睡眠時間(時間)/日"}
                            rangeAverageValue={{
                                start: sleepRange.averageSleepTimeRange.start,
                                end: sleepRange.averageSleepTimeRange.end,
                            }}
                            setRangeAverageValue={(value) =>
                                setRange(
                                    setSleepRange,
                                    "averageSleepTimeRange",
                                    value
                                )
                            }
                            styleStart={searchInput.startSleepInput}
                            styleEnd={searchInput.endSleepInput}
                            styleStartAverage={
                                searchInput.startAverageSleepInput
                            }
                            styleEndAverage={searchInput.endAverageSleepInput}
                            maxLength={4}
                            isSleep={true}
                            selectedMode={selectedMode}
                        />
                    </Column>
                )}
            </Row>
            <div className="search-container__button">
                <ActionBtn
                    text="検索する"
                    onClick={onSearchClick}
                    submit
                    color="primary"
                />
            </div>
        </Panel>
    );
};

export default SearchContainer;
