import React from "react";
import {
    LineChart,
    Line,
    AreaChart,
    Area,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

interface AppDataGraphProps {
    data: {
        heart_rate?: any;
        body_temperature?: any;
        sleep?: any;
        steps?: any;
        start?: string;
        end?: string;
    };
    periodType: "day" | "week" | "month";
}

const AppDataGraph: React.FC<AppDataGraphProps> = ({ data, periodType }) => {
    const { heart_rate, body_temperature, sleep, steps, start, end } = data;

    if (!data || !data.start || Object.keys(data).length === 0) {
        return <div>データがありません。</div>;
    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return `${date.getFullYear()}年${
            date.getMonth() + 1
        }月${date.getDate()}日`;
    };

    const formatHHMMJa = (value: number) => {
        const hours = Math.floor(value / 60);
        const minutes = value % 60;
        return `${hours}時間${minutes.toString().padStart(2, "0")}分`;
    };

    const renderDateRange = () => {
        if (!start) return null;

        let dateRangeText = "";
        if (formatDate(start) === formatDate(end)) {
            dateRangeText = formatDate(start);
        } else {
            dateRangeText = `${formatDate(start)} ~ ${
                end ? formatDate(end) : ""
            }`;
        }

        return (
            <div
                style={{
                    textAlign: "center",
                    margin: "20px 0",
                    fontSize: "18px",
                    fontWeight: "bold",
                }}
            >
                {dateRangeText}
            </div>
        );
    };

    const formatGraphData = (chart_spots) => {
        return chart_spots.map(c => {
            return {
                x_label: c.x_label,
                y_value: c.y_value || null
            }
        })
    }

    const renderHeartRateGraph = () => {
        if (!heart_rate?.chart_spots || heart_rate.chart_spots.length === 0) {
            return <div>心拍数のデータがありません。</div>;
        }
        return (
            <div>
                <h3>心拍数</h3>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={formatGraphData(heart_rate.chart_spots)}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="x_label" />
                        <YAxis
                            domain={[
                                heart_rate.chart_min_y,
                                heart_rate.chart_max_y,
                            ]}
                        />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="y_value"
                            stroke="#8884d8"
                            name="心拍数"
                        />
                    </LineChart>
                </ResponsiveContainer>
                <p>
                    平均: {heart_rate.average} / 最小: {heart_rate.minimum} / 最大:{" "}
                    {heart_rate.maximum}
                </p>
            </div>
        );
    };

    const renderBodyTemperatureGraph = () => {
        if (
            !body_temperature?.chart_spots ||
            body_temperature.chart_spots.length === 0
        ) {
            return <div>体温のデータがありません。</div>;
        }
        return (
            <div>
                <h3>体温</h3>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={formatGraphData(body_temperature.chart_spots)}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="x_label" />
                        <YAxis
                            domain={[
                                body_temperature.chart_min_y,
                                body_temperature.chart_max_y,
                            ]}
                        />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="y_value"
                            stroke="#82ca9d"
                            name="体温"
                        />
                    </LineChart>
                </ResponsiveContainer>
                <p>
                    平均: {body_temperature.average}℃ / 最小:{" "}
                    {body_temperature.minimum}℃ / 最大: {body_temperature.maximum}
                    ℃
                </p>
            </div>
        );
    };

    const renderSleepGraph = () => {
        if (!sleep?.chart_spots || sleep.chart_spots.length === 0) {
            return <div>睡眠のデータがありません。</div>;
        }

        return (
            <div>
                <h3>睡眠</h3>
                <ResponsiveContainer width="100%" height={300}>
                    {periodType === "day" ? (
                        <SleepQualityChart data={sleep.chart_spots} />
                    ) : (
                        <BarChart data={sleep.chart_spots}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="x_label" />
                            <YAxis
                                domain={[sleep.chart_min_y, sleep.chart_max_y]}
                            />
                            <Tooltip />
                            <Legend />
                            <Bar
                                dataKey="y_value"
                                fill="#8884d8"
                                name="睡眠時間"
                            />
                        </BarChart>
                    )}
                </ResponsiveContainer>
                {periodType === "day" ? (
                    <>
                        <p>
                            合計睡眠時間: {formatHHMMJa(sleep.total)} /
                             浅い睡眠: {sleep.light_sleep_percentage}% /
                             深い睡眠: {sleep.deep_sleep_percentage}% /
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            合計睡眠時間: {formatHHMMJa(sleep.total)} / 平均:{" "}
                            {sleep.average}分
                        </p>
                        <p>
                            浅い睡眠: {sleep.light_sleep_percentage}% / 深い睡眠:{" "}
                            {sleep.deep_sleep_percentage}%
                        </p>
                    </>
                )}
            </div>
        );
    };

    const renderStepsGraph = () => {
        if (!steps?.chart_spots || steps.chart_spots.length === 0) {
            return <div>歩数のデータがありません。</div>;
        }
        return (
            <div>
                <h3>歩数</h3>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={steps.chart_spots}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="x_label" />
                        <YAxis
                            domain={[steps.chart_min_y, steps.chart_max_y]}
                        />
                        <Tooltip />
                        <Legend />
                        <Line dataKey="y_value" fill="#82ca9d" name="歩数" />
                    </LineChart>
                </ResponsiveContainer>
                <p>
                    合計歩数: {steps.total_steps} / 距離: {steps.total_distance}km /
                     消費カロリー: {steps.total_calories_burned}kcal
                </p>
            </div>
        );
    };

    return (
        <div>
            {renderDateRange()}
            {renderHeartRateGraph()}
            {renderBodyTemperatureGraph()}
            {renderSleepGraph()}
            {renderStepsGraph()}
        </div>
    );
};

export default AppDataGraph;

interface SleepDataPoint {
    x_label: string;
    y_value: number | null;
}

interface SleepQualityChartProps {
    data: SleepDataPoint[];
}

const SleepQualityChart: React.FC<SleepQualityChartProps> = ({ data }) => {
    const CustomYAxisTick: React.FC<any> = ({ x, y, payload }) => {
        let text: string;
        if (payload.value === 0) {
            text = "覚醒";
        } else if (payload.value === 50) {
            text = "浅い";
        } else {
            text = "深い";
        }

        return (
            <text
                x={x}
                y={y}
                dy={5}
                textAnchor="end"
                fill="#757575"
                style={{
                    fontSize: "16px",
                }}
            >
                {text}
            </text>
        );
    };

    const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const sleepState =
                payload[0].value === 0
                    ? "覚醒"
                    : payload[0].value === 50
                    ? "浅い"
                    : "深い";
            return (
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        padding: "5px",
                        border: "1px solid #ccc",
                    }}
                >
                    <p>{`時間: ${label}`}</p>
                    <p>{`状態: ${sleepState}`}</p>
                    <p>{-payload[0].value}</p>
                </div>
            );
        }
        return null;
    };

    const hasData =
        data instanceof Array
            ? data.some((point) => point.y_value !== null)
            : false;

    return (
        <div style={{ position: "relative", width: "100%", height: 350 }}>
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                    data={data}
                    margin={{ top: 20, right: 30, left: 0, bottom: 30 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="x_label"
                        interval={0}
                        tick={{
                            fill: "#757575",
                            fontWeight: "bold",
                            fontSize: "8px",
                        }}
                    />
                    <YAxis
                        reversed
                        domain={[0, 100]}
                        ticks={[0, 50, 100]}
                        tick={<CustomYAxisTick />}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                        type="monotone"
                        dataKey="y_value"
                        stroke="#1976d2"
                        fill="#1976d2"
                        fillOpacity={0.05}
                    />
                </AreaChart>
            </ResponsiveContainer>
            {!hasData && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 32,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <span
                        style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#9e9e9e",
                        }}
                    >
                        データがありません
                    </span>
                </div>
            )}
        </div>
    );
};
