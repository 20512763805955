import * as React from "react";
import { BsEmojiExpressionless } from "react-icons/bs";
import {
    FaRegChartBar,
    FaUsers,
    FaGift,
    FaCog,
    FaBullhorn,
    FaCoins,
    FaSearch,
    FaHospitalUser,
    FaQrcode,
    FaHeartbeat,
	FaRing,
	FaChartLine,
} from "react-icons/fa";

export interface INavItem {
    id: string;
    text: string;
    route: string;
    color:
        | "primary"
        | "info"
        | "dark"
        | "light"
        | "warning"
        | "danger"
        | "success"
        | "secondary";
    icon: React.ReactElement;
    service: null | "リング" | "打刻";
    children?: INavItem[];
}BsEmojiExpressionless

export const navItems: Array<INavItem> = [
    {
        id: "home",
        text: "ホーム",
        route: "/",
        color: "primary",
        icon: <FaRegChartBar />,
        service: null,
    },
    {
        id: "employees",
        text: "社員管理",
        route: "/employees/list",
        color: "success",
        icon: <FaUsers />,
        service: null,
    },
    {
        id: "products-history",
		text: "利用明細",
		route: "/product/transaction-history",
		color: "warning",
		icon: <FaGift />,
		service: null,
	},
	{
		id: "products-usage",
		text: "社員の利用実績",
		route: "/usage",
		color: "info",
		icon: <FaCoins />,
		service: null,
    },
    {
        id: "announcements",
        text: "お知らせ配信",
        route: "/announcements",
        color: "primary",
        icon: <FaBullhorn />,
        service: null,
    },
    {
        id: "healthcare",
        text: "リング",
        route: "/healthcare",
        color: "primary",
        icon: <FaRing />,
        service: "リング",
        children: [
			{
				id: "healthcare-users",
				text: "リングユーザ一覧",
				route: "/ringUsers",
				color: "primary",
				icon: <FaUsers />,
				service: "リング",
			},
            {
                id: "healthcare-data",
                text: "健康データ",
                route: "/healthcare",
                color: "primary",
                icon: <FaHeartbeat />,
                service: "リング",
            },
            {
                id: "healthcare-search",
                text: "健康データ検索",
                route: "/healthcareSearch",
                color: "primary",
                icon: <FaHospitalUser />,
                service: "リング",
            },
			{
				id: "healthcare-graph",
				text: "健康データグラフ",
				route: "/healthcareGraph",
				color: "primary",
				icon: <FaChartLine />,
				service: "リング",
			},
            {
                id: "ring-search",
                text: "リング検索",
                route: "/ringSearch",
                color: "primary",
                icon: <FaSearch />,
                service: "リング",
            },
        ],
    },
    {
        id: "punch",
        text: "打刻",
        route: "/punch",
        color: "secondary",
        icon: <FaQrcode />,
        service: "打刻",
    },
    {
        id: "settings",
        text: "設定",
        route: "/settings",
        color: "secondary",
        icon: <FaCog />,
        service: null,
    },
];
